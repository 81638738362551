<script>
import { VDivider } from 'vuetify/lib'

let _VDivider = VDivider

export default {
  name: 'CtDivider',
  extends: _VDivider
}
</script>

<style>

</style>
