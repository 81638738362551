<script>
import { VDialog } from 'vuetify/lib'

let _VDialog = VDialog

export default {
  name: 'CtDialog',
  extends: _VDialog
}
</script>

<style>

</style>
